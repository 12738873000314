define([
  'module',
  'modules/common/events/event',
],
(Module, Event) => Event.extend({
  name: 'modules/profile/events/profile/beforeLoad',

  fields: {},

  /**
             * Set fields which are set when profile is fetched
             * @param fields
             */
  setFetchFields(fields) {
    this.fields = fields || {};
  },

  /**
             * Get the set fields which are set
             * @returns {exports.params|{fields}}
             */
  getFetchFields() {
    return this.fields;
  },
}));
