define([
  'modules/common/acls/anyAcl',
  'modules/shop.common/acls/commerceAuth',
  'modules/shop.common/components/commerceAuth',
], (
  AnyAcl, CommerceAuthAcl, CommerceAuthComp,
) => AnyAcl.extend({
  /**
         *
         * @param options
         */
  initialize(options) {
    AnyAcl.prototype.initialize.call(this, options);
    this.acls = options.acls || [
      new CommerceAuthAcl({ alias: CommerceAuthComp.adminAlias }),
      new CommerceAuthAcl({ alias: CommerceAuthComp.managerAlias }),
    ];
  },
}));
