define([
  'modules/common/acls/acl',
], (
  Acl,
) => Acl.extend({

  initialize(options) {
    this.acl = options;
  },

  authorized() {
    return !this.acl.authorized();
  },

  error(route, authorized) {
    if ('error' in this.acl) {
      this.acl.error(route, authorized);
    }
  },

}));
