define([
  'underscore',
  'backbone',
  'modules/common/acls/acl',
  'modules/shop.common/components/commerceAuth',
],
(_, Backbone, Acl, CommerceAuth) => {
  const CommerceAuthAcl = Acl.extend({

    initialize(options) {
      // Set prototypes
      Acl.prototype.initialize.call(this, options);
      this.alias = options.alias;
    },

    authorized() {
      if (_.isArray(this.alias)) {
        let auth = false;
        _.each(this.alias, (alias) => {
          if (CommerceAuth.hasAccessByProductAlias(alias) && !auth) {
            auth = true;
          }
        });
        return auth;
      }
      return CommerceAuth.hasAccessByProductAlias(this.options.alias);
    },

    error() {
      Backbone.history.navigate('', { trigger: true });
    },

  });

  CommerceAuthAcl.orderAlias = CommerceAuth.orderAlias;
  CommerceAuthAcl.quoteAlias = CommerceAuth.quoteAlias;
  CommerceAuthAcl.invoiceAlias = CommerceAuth.invoiceAlias;
  CommerceAuthAcl.contractAlias = CommerceAuth.contractAlias;
  CommerceAuthAcl.fileAlias = CommerceAuth.fileAlias;
  CommerceAuthAcl.productAlias = CommerceAuth.productAlias;
  CommerceAuthAcl.relationAlias = CommerceAuth.relationAlias;
  CommerceAuthAcl.supportAlias = CommerceAuth.supportAlias;
  CommerceAuthAcl.adminAlias = CommerceAuth.adminAlias;
  CommerceAuthAcl.managerAlias = CommerceAuth.managerAlias;
  CommerceAuthAcl.cashierAlias = CommerceAuth.cashierAlias;
  CommerceAuthAcl.orderOnlyAlias = CommerceAuth.orderOnlyAlias;

  return CommerceAuthAcl;
});
