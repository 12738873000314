define([
  'jquery',
  'backbone',
  'module',
  'modules/common/components/module',
  'modules/upx/collections/users',
  'modules/profile/models/profile',
],
($, Backbone, Module, AppModule, UserCollection, ProfileModel) => {
  const module = new AppModule({
    id: 'modules/profile/application',

    onStart() {
      const hashUser = UserCollection.findWhere({
        active: true,
        mode: 'hash',
      });

      const apiUser = UserCollection.findWhere({
        active: true,
        mode: 'apikey',
      });

      // if we are logged in using api or hash, load the profile
      if (hashUser !== undefined || apiUser !== undefined) {
        const def = $.Deferred();
        let waitDef = true;
        let user;

        if (hashUser !== undefined) {
          waitDef = hashUser.refreshSessionDeffer;
          user = hashUser;
        }

        if (apiUser !== undefined) {
          user = apiUser;
        }

        $.when(waitDef).then(() => {
          $.when(ProfileModel.load(user)).then(
            () => {
              def.resolve();
            },
            () => {
              def.reject();
            },
          );
        }, () => {
          def.reject();
        });

        Backbone.Preloader.queue = Backbone.Preloader.queue || {};
        Backbone.Preloader.queue.profile = def.promise();
      }
    },
  });

  return module.register();
});
